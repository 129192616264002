import React, { useEffect, useRef } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { GatsbyImage } from "gatsby-plugin-image";
import useOnScreen from "./use-on-screen";
import gsap from "gsap";

const PhotoLeft = ({ img, imgAlt, text, bgColour, imgHeight }) => {
	const ref1 = useRef();
	const onScreen1 = useOnScreen(ref1, "0px");
	const newHeight = imgHeight || "";

	useEffect(() => {
		if (onScreen1 === true) {
			gsap.to(".main-right", {
				x: 0,
				duration: 2,
				delay: 0.2,
			});
			gsap.to(".main-left", {
				x: 0,
				duration: 2,
				delay: 0.2,
			});
		}
	}, [onScreen1]); //THIS IS RUN THE FIRST TIME THE SITE IS OPENED
	return (
		<Container ref={ref1} className="px-0 mx-0" fluid>
			<Row className={`${bgColour} align-items-center`}>
				<Col className={`d-none main-left d-lg-block`} lg={6}>
					<div className="photo-left-angle mb-4 mb-lg-0">
						<GatsbyImage
							style={{ height: newHeight }}
							className="photo-left-image"
							image={img}
							alt={imgAlt}
						/>
					</div>
				</Col>
				<Col
					className={`px-xl-6 px-4 main-right d-none d-lg-block  py-5 py-lg-0  px-md-5 
						`}
					lg={6}
				>
					{text}
				</Col>
				<Col
					className={`px-xl-6 px-4 d-lg-none  py-5 py-lg-0  px-md-5 
						`}
					lg={6}
				>
					{text}
				</Col>
			</Row>
		</Container>
	);
};

export default PhotoLeft;
