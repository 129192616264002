import { useState, useEffect } from "react";

export default function useOnScreen(ref, rootMargin = "0px") {
	// State and setter for storing whether element is visible
	const [isIntersecting, setIntersecting] = useState(false);
	var num = 0;
	useEffect(() => {
		const observer = new IntersectionObserver(
			([entry]) => {
				// Update our state when observer callback fires
				if (num === 1) {
					setIntersecting(true);
				} else if (num === 0) {
					setIntersecting(entry.isIntersecting);
					num = 1;
				}
			},
			{
				rootMargin,
			}
		);
		if (ref.current) {
			observer.observe(ref.current);
		}
		return () => {
			observer.unobserve(ref.current);
		};
	}, []); // Empty array ensures that effect is only run on mount and unmount

	return isIntersecting;
}
