import React from "react";
import Col from "react-bootstrap/Col";
import { GatsbyImage } from "gatsby-plugin-image";

const TreatmentCard = ({
	image,
	title,
	text,
	imgAlt,
	span,
	imageHeight,
	price,
	headingHeight,
	shorter,
	objPos,
	extraPadding,
}) => {
	const hHeight = headingHeight || "auto";
	return (
		<Col
			style={{ minHeight: "100%" }}
			className="mb-6 mb-xl-0"
			md={6}
			lg={5}
			xl={{ span: span }}
		>
			<div
				style={{
					boxShadow: "0px 3px 50px #00000029",
					background: "none",
					overflow: "hidden",
				}}
				className={` w-100 h-100 feature-box  pb-5 position-relative`}
			>
				<div className={`position-relative d-lg-none  `}>
					<div style={{ zIndex: 0, overflow: "hidden" }} className={`   `}>
						<GatsbyImage
							style={{ height: "220px" }}
							className=""
							image={image}
							alt={imgAlt}
							objectPosition={objPos}
						/>
					</div>
				</div>
				<div
					style={{ height: imageHeight }}
					className={`position-relative d-none d-lg-block ${shorter} `}
				>
					<div
						style={{ zIndex: 0, overflow: "hidden" }}
						className={` position-absolute treatment-card-image-container top-0 `}
					>
						<GatsbyImage
							className="treatment-card-image"
							image={image}
							alt={imgAlt}
						/>
					</div>
				</div>
				<div
					style={{ width: "calc(100% + 3px)", left: "-1px", zIndex: 2 }}
					className={`bg-white d-none d-lg-block px-4 pb-3 pt-7 position-relative ${
						extraPadding ? "pt-9" : "pt-7"
					}  card-div`}
				>
					<h3 style={{ height: hHeight }} className="fs-3 text-center pb-3 ">
						{title}
					</h3>
					<p>{text}</p>
				</div>
				<div
					style={{ width: "calc(100% + 3px)", left: "-1px", zIndex: 2 }}
					className="bg-white d-lg-none px-4 pb-3 pt-6 position-relative  "
				>
					<h3 style={{ height: hHeight }} className="fs-3 text-center pb-3 ">
						{title}
					</h3>
					<div>{text}</div>
				</div>

				{price && (
					<div style={{ zIndex: 2 }} className="bg-white ">
						<div
							style={{ zIndex: 2 }}
							className="pt-6 bg-white position-relative"
						></div>
						<div
							style={{ zIndex: 3 }}
							className="text-center position-absolute w-100 py-5 bottom-0"
						>
							<p className="text-text-secondary text-center jost-bold">
								From {price}
							</p>
						</div>
					</div>
				)}
			</div>
		</Col>
	);
};

export default TreatmentCard;
